import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { auth, servicesCollection, storage } from '@/firebase'
import { companiesCollection, equipmentsCollection } from '../firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: {},
    error: '',
    subcriptionCompanies: null,
    companies: [],
    loadingCompanies: false,
    selectedCompany: {},
    uploadingCompany: false,
    subcriptionEquipments: null,
    equipments: [],
    loadingEquipments: false,
    selectedEquipment: {},
    uploadingEquipment: false,
    subcriptionServices: null,
    services: [],
    loadingServices: false,
    selectedService: {},
    loadingService: false,
    uploadingService: false,
    searchedEquipments: null,
    searchedServices: null,
    searching: false,
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setError(state, val) {
      state.error = val;
      if (val != '' && state.error != '')
        setTimeout(() => state.error = '', 5000);
    },
    commitCompanies(state, val) {
      state.companies = val;
    },
    commitEquipments(state, val) {
      state.equipments = val;
    },
    commitServices(state, val) {
      state.services = val;
    },
  },
  actions: {
    async searchServicesByEquipmentId({ state }, equipmentId) {
      state.searching = true;
      state.searchedServices = null;
      servicesCollection.where("equipmentId", '==', equipmentId).limit(20).get().then(function (doc) {
        if (doc.docs.length > 0) {
          state.searchedServices = doc.docs.map(d => {
            return {
              id: d.id,
              ...d.data(),
            }
          }).sort((a, b) => {
            if (a.date < b.date) {
              return 1;
            }
            if (a.date > b.date) {
              return -1;
            }
            return 0;
          })
        } else {
          state.searchedServices = null;
        }
        state.searching = false;
      }).catch(function (error) {
        state.searching = false;
      })
    },
    async searchEquipmentsByCompanyId({ state, dispatch }, companyId) {
      state.searching = true;
      state.searchedEquipments = null;
      await dispatch('selectCompany', companyId)
      equipmentsCollection.where("companyId", '==', companyId).limit(20).get().then(function (doc) {
        if (doc.docs.length > 0) {
          state.searchedEquipments = doc.docs.map(d => {
            return {
              id: d.id,
              ...d.data(),
            }
          })
        } else {
          state.searchedEquipments = null;
        }
        state.searching = false;
      }).catch(function (error) {
        state.searching = false;
      })
    },
    async searchEquipmentsByCustomId({ state, dispatch }, customId) {
      state.searching = true;
      state.searchedEquipments = null;
      await dispatch('selectCompanyByCustomId', customId)
      if (state.selectedCompany?.id) {
        equipmentsCollection.where("companyId", '==', state.selectedCompany.id).limit(20).get().then(function (doc) {
          if (doc.docs.length > 0) {
            state.searchedEquipments = doc.docs.map(d => {
              return {
                id: d.id,
                ...d.data(),
              }
            })
          } else {
            state.searchedEquipments = null;
          }
          state.searching = false;
        }).catch(function (error) {
          state.searching = false;
        })
      } else {
        state.searching = false;
      }
    },
    async login({ dispatch, commit }, form) {
      try {
        const { user } = await auth.signInWithEmailAndPassword(form.email, form.password);
        dispatch('fetchUserProfile', user)
      } catch (error) {
        console.log(error);
        commit('setError', error.code)
      }
    },
    async logout({ state }) {
      state.companies = [];
      state.equipments = [];
      state.service = [];
      state.selectedCompany = {}
      state.selectedEquipment = {}
      state.selectedService = {}
      if (state.subcriptionCompanies)
        state.subcriptionCompanies()
      if (state.subcriptionEquipments)
        state.subcriptionEquipments()
      if (state.subcriptionServices)
        state.subcriptionServices()
      await auth.signOut();
      router.push({ name: 'Login' });
    },
    async fetchUserProfile({ commit }, user) {
      // const userProfile = await firestore.usersCollection...
      // commit('setUserProfile', userProfile.data())
      router.push('/admin')
    },
    async setError({ commit }, val) {
      commit('setError', val);
    },
    async getCompanies({ state, commit }) {
      if (state.companies.length == 0) {
        state.loadingCompanies = true;
        if (state.subcriptionCompanies != null) {
          state.subcriptionCompanies();
        }
        commit('commitCompanies', [])
        state.subcriptionCompanies = await companiesCollection.onSnapshot(function (doc) {
          if (state.loadingCompanies) state.loadingCompanies = false;
          const companies = doc.docs.map((d) => {
            let data = d.data()
            data.id = d.id;
            return data;
          });
          commit('commitCompanies', companies)
        }, function (error) {
          if (state.loadingCompanies) state.loadingCompanies = false;
        });
      }
    },
    async getEquipments({ state, commit, dispatch }, companyId) {
      const oldId = state.selectedCompany.id;
      if (companyId != oldId) {
        state.loadingEquipments = true;
        state.selectedCompany = {};
        commit('commitEquipments', [])
      }
      await dispatch('selectCompany', companyId)
      if (state.equipments.length == 0 || companyId != oldId) {
        state.loadingEquipments = true;
        if (state.subcriptionEquipments != null) {
          state.subcriptionEquipments();
        }
        state.subcriptionEquipments = await equipmentsCollection.where("companyId", '==', companyId).onSnapshot(function (doc) {
          if (state.loadingEquipments) state.loadingEquipments = false;
          const equipments = doc.docs.map((d) => {
            let data = d.data()
            data.id = d.id;
            return data;
          });
          commit('commitEquipments', equipments)
        }, function (error) {
          if (state.loadingEquipments) state.loadingEquipments = false;
        });
      }
    },
    async getServices({ state, commit, dispatch }, equipmentId) {
      const oldId = state.selectedEquipment.id;
      if (equipmentId != oldId) {
        state.selectedEquipment = {};
        commit('commitServices', [])
      }
      await dispatch('selectEquipment', equipmentId)
      if (state.services.length == 0 || equipmentId != oldId) {
        state.loadingServices = true;
        if (state.subcriptionServices != null) {
          state.subcriptionServices();
        }
        state.subcriptionServices = await servicesCollection.where("equipmentId", '==', equipmentId).onSnapshot(function (doc) {
          if (state.loadingServices) state.loadingServices = false;
          const services = doc.docs.map((d) => {
            let data = d.data()
            data.id = d.id;
            return data;
          });
          commit('commitServices', services)
        }, function (error) {
          if (state.loadingServices) state.loadingServices = false;
        });
      }
    },
    async deleteCompany({ }, companyId) {
      try {
        await companiesCollection.doc(companyId).delete()
      } catch (error) {
        console.log(error)
      }
    },
    async deleteEquipment({ }, equipmentId) {
      try {
        await equipmentsCollection.doc(equipmentId).delete()
      } catch (error) {
        console.log(error)
      }
    },
    async deleteService({ }, serviceId) {
      try {
        await servicesCollection.doc(serviceId).delete()
        await storage.ref().child('pdfs').child(serviceId + '.pdf').delete();
      } catch (error) {
        console.log(error)
      }
    },
    async selectCompanyByCustomId({ state }, customId) {
      state.loadingCompanies = true;
      state.selectedCompany = {};
      await companiesCollection.where('customId', '==', customId).limit(1).get().then(function (doc) {
        if (!doc.docs.empty) {
          let data = doc.docs[0].data()
          data.id = doc.docs[0].id
          state.selectedCompany = data
        } else {
          state.selectedCompany = null;
          // TODO: navegar a administracion y mostrar mensaje de advertencia
        }
        state.loadingCompanies = false;
      }).catch(function (error) {
        console.log(error);
        state.loadingCompanies = false;
      })
    },
    async selectCompany({ state }, companyId) {
      state.loadingCompanies = true;
      await companiesCollection.doc(companyId).get().then(function (doc) {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          state.selectedCompany = data
        } else {
          state.selectedCompany = null;
          // TODO: navegar a administracion y mostrar mensaje de advertencia
        }
        state.loadingCompanies = false;
      }).catch(function (error) {
        console.log(error);
        state.loadingCompanies = false;
      })
    },
    async selectEquipment({ state }, equipmentId) {
      state.loadingEquipments = true;
      await equipmentsCollection.doc(equipmentId).get().then(function (doc) {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          state.selectedEquipment = data
        } else {
          state.selectedEquipment = null;
          // TODO: navegar a administracion y mostrar mensaje de advertencia
        }
        state.loadingEquipments = false;
      }).catch(function (error) {

        state.loadingEquipments = true;
      })
    },
    async selectService({ state }, serviceId) {
      state.loadingService = true;
      servicesCollection.doc(serviceId).get().then(function (doc) {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          state.selectedService = data
        } else {
          state.selectedService = null;
          // TODO: navegar a administracion y mostrar mensaje de advertencia
        }
        state.loadingService = false;
      }).catch(function (error) {

        state.loadingService = true;
      })
    },
    async updateCompany({ state }, data) {
      state.uploadingCompany = true;
      try {
        if (!data.companyId) {
          const ref = await companiesCollection.add(data.company)
          data.companyId = ref.id;
        } else {
          await companiesCollection.doc(data.companyId).update(data.company);
        }
        state.uploadingCompany = false;
        router.push({ name: 'Companies' })
      } catch (error) {
        console.log(error);
        state.uploadingCompany = false;
      }
    },
    async updateEquipment({ state }, data) {
      state.uploadingEquipment = true;
      try {
        if (!data.equipmentId) {
          const ref = await equipmentsCollection.add(data.equipment)
          data.equipmentId = ref.id;
        } else {
          await equipmentsCollection.doc(data.equipmentId).update(data.equipment);
        }
        state.uploadingEquipment = false;
        router.push({ name: 'Company', params: { companyId: router.history.current.params.companyId } })
      } catch (error) {
        console.log(error);
        state.uploadingEquipment = false;
      }
    },
    async uploadPdf({ state }, data) {
      const ref = storage.ref().child('pdfs').child(data.serviceId + '.pdf');
      ref.put(data.file).then(async function (val) {
        const url = await val.ref.getDownloadURL()
        await servicesCollection.doc(data.serviceId).update({ pdf: url });
        state.uploadingService = false;
        router.push({ name: 'Equipment', params: { companyId: router.history.current.params.companyId, equipmentId: router.history.current.params.equipmentId, } })
      }).catch(function (err) {
        state.uploadingService = false;
      })
    },
    async updateService({ dispatch, state }, data) {
      state.uploadingService = true;
      try {
        if (!data.serviceId) {
          const ref = await servicesCollection.add(data.service)
          data.serviceId = ref.id;
        } else {
          await servicesCollection.doc(data.serviceId).update(data.service);
        }
        if (data.file != null) {
          await dispatch('uploadPdf', data);
        }
        else {
          state.uploadingService = false;
          router.push('/admin')
        }

      } catch (error) {
        console.log(error);
        state.uploadingService = false;
      }
    }
  },
  modules: {
  }
})
