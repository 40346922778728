import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'
import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cliente/:customId',
    name: 'Client',
    component: () => import(/* webpackChunkName: "client" */ '@/views/client.vue')
  },
  {
    path: '/cliente/:customId/equipo/:equipmentId',
    name: 'Client Services',
    component: () => import(/* webpackChunkName: "client" */ '@/views/client.vue')
  },
  {
    path: '/admin',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'Companies' },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin.vue'),
    children: [
      {
        path: 'empresas',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "companies" */ '@/components/companies.vue'),
      },
      {
        path: 'nueva-empresa',
        name: 'New Company',
        component: () => import(/* webpackChunkName: "new company" */ '@/components/company.vue'),
      },
      {
        path: 'editar-empresa/:companyId',
        name: 'Edit Company',
        component: () => import(/* webpackChunkName: "edit company" */ '@/components/company.vue'),
      },
      {
        path: 'empresa/:companyId',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '@/components/equipments.vue'),
      },
      {
        path: 'empresa/:companyId/nuevo-equipo',
        name: 'New Equipment',
        component: () => import(/* webpackChunkName: "new equipment" */ '@/components/equipment.vue'),
      },
      {
        path: 'empresa/:companyId/editar-equipo/:equipmentId',
        name: 'Edit Equipment',
        component: () => import(/* webpackChunkName: "edit equipment" */ '@/components/equipment.vue'),
      },
      {
        path: 'empresa/:companyId/equipo/:equipmentId',
        name: 'Equipment',
        component: () => import(/* webpackChunkName: "edit equipment" */ '@/components/services.vue'),
      },
      {
        path: 'empresa/:companyId/equipo/:equipmentId/nuevo-servicio',
        name: 'New Service',
        component: () => import(/* webpackChunkName: "new service" */ '@/components/service.vue'),
      },
      {
        path: 'empresa/:companyId/equipo/:equipmentId/editar-servicio/:serviceId',
        name: 'Edit Service',
        component: () => import(/* webpackChunkName: "edit service" */ '@/components/service.vue'),
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    beforeEnter: (to, from, next) => {
      if (!!auth.currentUser) {
        next('/admin')
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
