<template>
  <v-container>
    <v-card elevation="2" :color="cardColor" dark class="card">
      <slot></slot>
    </v-card>
    <v-footer dark padless absolute max-width="900" class="mx-auto">
      <v-card class="flex rounded-t" flat tile>
        <v-card-title class="background">
          <strong>Indutec Bahía</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            href="https://www.instagram.com/indutecbahia/"
            target="_blank"
            class="mx-1"
          >
            <v-icon> mdi-instagram </v-icon>
          </v-btn>
          <v-btn
            icon
            href="https://www.facebook.com/indutec.redes"
            target="_blank"
            class="mx-1"
          >
            <v-icon> mdi-facebook </v-icon>
          </v-btn>
          <v-btn icon href="tel:5492916490590" target="_blank" class="mx-1">
            <v-icon> mdi-phone </v-icon>
          </v-btn>
          <v-btn
            icon
            href="https://api.whatsapp.com/send/?phone=5492916490590"
            target="_blank"
            class="mx-1"
          >
            <v-icon> mdi-whatsapp </v-icon>
          </v-btn>
          <v-btn
            icon
            href="mailto:Administracion@indutecbahia.com.ar"
            target="_blank"
            class="mx-1"
          >
            <v-icon> mdi-email </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-4 white--text text-center">
          <v-btn icon :to="{ name: 'Companies' }">
            <v-icon> mdi-security </v-icon>
          </v-btn>
          Copyright © {{ new Date().getFullYear() }} —
          <strong
            >Creado y mantenido por
            <a href="https://nicolasrt.xyz/" target="_blank" class="author"
              >NicolasRT</a
            ></strong
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "Card",
  props: ["color"],
  computed: {
    cardColor() {
      if (this.color) return this.color;
      return "#0049a0";
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 915px !important;
}
.background {
  background-color: #0049a0;
  font-size: 16px;
}
.author {
  text-decoration-line: none;
  color: #488fef;
}
</style>