import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyB9oq6ehJ3kT3VhaPNBIm7YD_85kODV80U",
    authDomain: "indutec-5d64e.firebaseapp.com",
    projectId: "indutec-5d64e",
    storageBucket: "indutec-5d64e.appspot.com",
    messagingSenderId: "403441416313",
    appId: "1:403441416313:web:59f1f2d450c508e3bbfca8",
    measurementId: "G-BD5RBHVWN3"
}

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

const companiesCollection = firestore.collection('companies');
const equipmentsCollection = firestore.collection('equipments');
const servicesCollection = firestore.collection('services');

export {
    auth,
    firestore,
    storage,
    companiesCollection,
    equipmentsCollection,
    servicesCollection
}