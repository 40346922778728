<template>
  <v-app>
    <v-main>
      <Header></Header>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/header.vue";

export default {
  name: "App",

  components: {
    Header,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="css">
html {
  overflow-y: auto;
}
.v-main {
  background-color: #060708;
}
</style>