<template>
  <div>
    <Card class="md-15 mt-15" style="margin-bottom: 200px">
      <v-card-title> Buscar por Id de cliente </v-card-title>
      <v-card-subtitle>
        Encuentra los datos del servicio con el id del cliente
      </v-card-subtitle>

      <v-card-text>
        <div class="mx-auto">
          <v-text-field
            v-model="customId"
            solo
            label="Id de cliente"
            hint="Respetar mayúsculas y minúsculas"
            prepend-inner-icon="mdi-car"
            append-icon="mdi-send"
            @click:append="searchByCustomId"
            v-on:keyup.enter="searchByCustomId"
          ></v-text-field>
        </div>
      </v-card-text>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/card.vue";
export default {
  name: "Home",
  components: {
    Card,
  },
  data: () => ({
    customId: "",
  }),
  created() {
    document.title = "Indutec Bahía";
  },
  methods: {
    searchByCustomId() {
      if (this.customId.trim() != "")
        this.$router.push({
          name: "Client",
          params: { customId: this.customId.trim() },
        });
    },
  },
};
</script>