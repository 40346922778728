<template>
  <div class="d-flex justify-center">
    <router-link to="/" class="link">
      <v-img
        src="@/assets/banner_color.png"
        contain
        max-width="900"
        class="d-none d-md-block banner"
      ></v-img>
      <v-img
        src="@/assets/logo.png"
        contain
        max-width="200"
        class="d-block d-md-none logo"
      ></v-img>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
.link {
  max-width: 100%;
}
.banner {
  margin: 50px 0px 0px 0px;
  padding: 0px 200px;
}
.logo {
  margin: 50px;
}
</style>